.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 1rem 1rem;
}

.buttonsContainer {
    --text-margin-block: 1rem;

    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

@keyframes waveEffectDisplayMode1 {
    0% {
        left: var(--animation-start);
    }
    100% {
        left: var(--animation-end);
    }
}

.button.button {
    cursor: pointer;
    overflow: hidden;
    position: relative;
    width: 45%;
    box-shadow: var(--box-shadow-element-higher-layer);
    border-radius: 5px;
    background-color: var(--color-essence-transparent);
    color: var(--color-white);
    vertical-align: middle;
    border: var(--border-two-px-groove-grey);

    &:hover {
        border-color: var(--color-essence);
        background-color: var(--color-blue-transparent);
        color: var(--color-white);
        border: var(--border-two-px-groove-purple);
    }
}

.button.button::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 120%;
    height: 100%;
    background: linear-gradient(0deg, transparent, var(--color-purple), transparent);
    transform: rotate(45deg);
    animation: waveEffectDisplayMode1 10s infinite linear;
    z-index: 1;
}

.button.create {
    --animation-start: -120%;
    --animation-end: 400%;
    --button-margin-left: 0;
}

.button.join {
    --animation-start: -220%;
    --animation-end: 300%;
    --button-margin-left: 5rem;
}

.button.standalone {
    width: 100%;
}

.icon.icon {
    min-width: 2.19rem;
}

.text {
    font-size: 1.1rem;
    line-height: 1.334;
    text-transform: capitalize;
    margin-block: var(--text-margin-block);
    padding: 0;
}


.isMobile {
    --text-margin-block: 0.5rem;
}

