:root {
    --widget-font-size: 1.563rem;
    --widget-font-size-mobile: 1.5rem;
    --tooltip-font-size: 0.7rem;
    --button-font-size: 0.8rem;

    @media (max-width: 599px) {
        --widget-font-size: 0.938rem;
        --tooltip-font-size: 0.3rem;
    }

    @media (min-width: 600px) {
        --widget-font-size: 1.3rem;
        --tooltip-font-size: 0.5rem;
    }

    @media (min-width: 900px) {
        --widget-font-size: 1.563rem;
        --tooltip-font-size: 0.7rem;
    }

    @media (min-width: 1200px) {
        --widget-font-size: 1.875rem;
        --tooltip-font-size: 0.8rem;
    }
}
