.fadeCanvas {
    position: fixed;
    top: 0;
    left: 0;
    animation: tailFade 2s ease-in-out 10s forwards;
}

.bolt {
    --initial-x: 0;
    --initial-y: 0;
    --final-x: 0;
    --final-y: 0;
    --movement-duration: '0s';

    position: absolute;
    left: -0.25rem;
    top: -0.25rem;
    animation: moveBolt var(--movement-duration) linear forwards;
}

@keyframes moveBolt {
    from {
        transform: translate(var(--initial-x), var(--initial-y));
    }
    to {
        transform: translate(var(--final-x), var(--final-y));
    }
}

@keyframes tailFade {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.energyCore {
    --bolt-color: var(--color-white);

    width: 0.75rem;
    height: 0.75rem;
    opacity: 0.8;
    filter: blur(0.3rem) contrast(70%);
    background-color: var(--bolt-color);
    box-shadow: 0 0 0.9rem 0.9rem var(--bolt-color);
    border-radius: 50%;
    animation: pulseEnergy 0.6s ease-in-out infinite;
}

@keyframes pulseEnergy {
    0% {
        transform: scale(0.9);
        filter: blur(0.2rem);
        opacity: 1;
    }
    50% {
        transform: scale(1.15);
        filter: blur(0.4rem);
        opacity: 0.8;
    }
    100% {
        transform: scale(0.9);
        filter: blur(0.2rem);
        opacity: 1;
    }
}
