.iconButton.iconButton {
    background-color: var(--color-player-background-transparent);
    padding: 0.5rem;
    margin-right: 0.25rem;
}

.iconElement.iconElement {
    --icon-element-font-size: var(--widget-font-size);

    font-size: var(--icon-element-font-size);
}

.iconElement.isMobile {
    --icon-element-font-size: var(--widget-font-size-mobile);
}

.tooltip {
    margin: auto;
    font-size: var(--tooltip-font-size);
}
