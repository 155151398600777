.container {
    background-color: var(--color-purple-very-dark-transparent);
    border: 2px solid var(--color-white);
    width: 40%;
    height: fit-content;
    padding: 1rem;
    margin: 0.4rem;
    z-index: var(--z-index-one);
}

.text {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.playableSpace {
    flex: 1;
    position: relative;
}

.probabilityCapsule {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 35px;
}

.cardsAndPlaceholderContainer {
    --playable-space-reserved-height: 60px;
    margin: 0.5rem auto auto;
    border-radius: 1rem;
    height: calc(var(--playable-space-reserved-height) + var(--card-dimension));
    display: grid;
    align-content: center;
}

.placeholder {
    margin: auto;
    padding: 0.05rem 0.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    align-content: center;
    font-size: 0.5rem;
    text-shadow: var(--text-shadow-default);
}

@media (min-width: 900px) {
    .placeholder {
        font-size: 0.7rem;
    }
}

@media (min-width: 1200px) {
    .placeholder {
        font-size: 1rem;
    }
}

.cards {
    overflow: auto;
    padding: 0.5rem;
}
