.container.container {
    --container-margin: 0 0 0.25rem;
    --container-padding: 0.375rem 2.5rem;
    --container-max-height: 2.813rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: var(--container-margin);
    padding: var(--container-padding);
    color: var(--color-turquoise);
    max-height: var(--container-max-height);
}

.listItemButton.listItemButton {
    color: var(--color-turquoise);
}

.button {
    --text-case: uppercase;
    margin-left: 0.8rem;
    text-transform: var(--text-case);
    font-size: var(--button-font-size);
}

.container.isMobileContainer {
    --container-max-height: initial;
    --container-margin: 0;
    --container-padding: 0 0;
}

.isMobileButton {
    --text-case: none;
    --container-padding: 0 0;
}
