.standard {
    display: flex;
    width: 100%;
}

.compact {
    composes: standard;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.card {
    margin: 0.5%;
}

.placeholderCard {
    composes: card;
}

.activeCard {
    composes: card;
    cursor: pointer;
}
