.container {
    --button-container-height: calc(var(--card-dimension) * 0.4);
    --button-container-width: 100%;

    height: var(--button-container-height);
    width: var(--button-container-width);
}

.mobileContainer {
    --button-container-height: calc(var(--card-dimension) * 0.8);
    --button-container-width: var(--card-dimension);
}

.trayContainer {
    --button-container-height: calc(var(--card-dimension) * 0.35);
}

.buttonContainer {
    cursor: pointer;
    width: 100%;
    height: 100%;
}
