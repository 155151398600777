@import "css/variables/variables.css";

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: 'cousine';
    src: local('cousine'), url('./fonts/Cousine-Regular.ttf') format("truetype");
}

@font-face {
    font-family: 'cousine-bold';
    src: local('cousine-bold'), url('./fonts/Cousine-Bold.ttf') format("truetype");
}

@font-face {
    font-family: 'cousine-bold-italic';
    src: local('cousinebold-italic'), url('./fonts/Cousine-BoldItalic.ttf') format("truetype");
}

@font-face {
    font-family: 'cousine-italic';
    src: local('cousine-italic'), url('./fonts/Cousine-Italic.ttf') format("truetype");
}
