.container {
    position: absolute;
    right: 0.2rem;
    top: 0.2rem;
}

.button {
    border-radius: 5rem;
    justify-content: end;
    align-items: end;
    padding: 0.35rem;
    min-width: 0;
}
