.text {
    --label-font-size: clamp(0.4rem, 1vw, 0.8rem);
    --label-position: relative;
    --label-bottom: 0;

    margin-bottom: 0.5%;
    line-height: 0.5rem;
    font-size: var(--label-font-size);
    text-shadow: var(--label-text-shadow);
    text-align: center;
    position: var(--label-position);
    bottom: var(--label-bottom);
    z-index: var(--z-index-one);
}

.default {
    --label-text-shadow: var(--text-shadow-default);
}

.red {
    --label-text-shadow: var(--text-shadow-red);
}

.isDisplayedOver {
    --label-position: absolute;
    --label-bottom: 3%;
}

.isMobile {
    --label-font-size: 0.65rem;
    --label-bottom: 20%;
}
