.container {
    --button-container-height: var(--card-dimension);
    --button-container-width: var(--card-dimension);

    height: var(--button-container-height);
    width: var(--button-container-width);
    position: absolute;
    z-index: var(--z-index-one);
}

.mobileContainer {
    z-index: var(--z-index-three);
}

.buttonContainer {
    cursor: pointer;
    width: 100%;
    height: 100%;
}

.button.button {
    --active-button-width: 260%;
    --active-button-height: 60%;
    --active-button-animation-start-left: -200%;

    background-color: var(--color-blue-transparent);
}

@media (hover: hover) {
    .button.button:hover {
        border-color: var(--color-essence);
        background-color: var(--color-blue-transparent);
        color: var(--color-white);
    }
}

.skipIcon.skipIcon {
    color: var(--color-scarlet);
    font-size: calc(var(--card-dimension) * 0.35);
    position: relative;
    top: calc(var(--card-dimension) * -0.32);
    left: calc(var(--card-dimension) * 0.32);
}
