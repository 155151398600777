.mainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-purple-very-dark-transparent);
}

.socialButtonsContainer {
    display: flex;
    justify-content: center;
}

.notification {
    display: flex;
    justify-content: center;
    text-align: center;
    padding-right: 0.4rem;
    font-size: 1rem;
}

.iconButton {
    padding: 0.4rem;
    line-height: 1;
    color: var(--color-white);
}
