.container {
    --container-height: calc(var(--card-dimension) * 2.2);

    position: fixed;
    bottom: 0;
    left: 1%;
    max-width: 98%;
    width: 98%;
    padding: 0 1rem 1rem;
    background-color: var(--color-action-drawer-mobile);
    border: 1px solid var(--color-purple);
    border-bottom: 0;
    z-index: var(--z-index-mobile-action-drawer);
    animation: var(--container-animation);
}

.cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
}

.text {
    text-align: center;
    font-weight: normal;
    margin: 0 0 0.25rem 0;
}

.textContainer {
    border-bottom: 1px solid var(--color-purple);
    margin: 0.5rem 0;
}

.content {
    padding-top: 0.25rem;
    max-height: 75%;
    overflow-y: auto;
}

@keyframes collapse {
    from {
        height: var(--container-height);
    }
    to {
        height: 0;
        display: none;
    }
}

.collapsible  {
    --container-animation: collapse 0.5s linear forwards;
}

@keyframes expand {
    from {
        height: 0;
        display: none;
    }
    to {
        height: var(--container-height);
    }
}

.expandable {
    --container-animation: expand 0.5s linear forwards;
}

.dimmer {
    --dimmer-display: block;

    position: fixed;
    top: 0;
    left: 0;
    width: var(--window-width);
    height: var(--window-height);
    background-color: var(--color-purple-very-dark-transparent);
    z-index: 1500;
    display: var(--dimmer-display);
}

.isHidden {
    --dimmer-display: none;
}
