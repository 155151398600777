.container {
    top: 0;
    left: 0;
    position: fixed;
    z-index: var(--z-index-pulsating-card);
}

@keyframes pulseAnimation {
    0%, 100% {
        transform: scale(var(--initial-scale));
    }
    20%, 60% {
        transform: scale(var(--growing-scale));
    }
    40%, 80% {
        transform: scale(var(--full-scale));
    }
}

.pulsatingCard {
    --initial-scale: 1;
    --growing-scale: 1.25;
    --full-scale: 1.5;

    animation: pulseAnimation 2s 0.5s forwards;
}

.isMobile {
    --initial-scale: 1;
    --growing-scale: 2.2;
    --full-scale: 3.5;

    scale: 0.4;
}

@keyframes moveToSafeAndBack {
    0% {
        transform: translate(0px, 0px);
    }
    15% {
        transform: translate(var(--safeX, 0), var(--safeY, 0));
    }
    75% {
        transform: translate(var(--safeX, 0), var(--safeY, 0));
    }
    100% {
        transform: translate(0px, 0px);
    }
}

.moveToSafeAndBack {
    --safeX: 0px;
    --safeY: 0px;

    animation: moveToSafeAndBack 4s forwards;
}
