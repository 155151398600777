.pleaseRotateBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.widgetsContainer {
    position: relative;
    z-index: var(--z-index-room-widgets);
}
