.defaultScrollbar {
    --scrollbar-width: 0.438rem;
    --scrollbar-height: 0.438rem;

    &::-webkit-scrollbar {
        width: var(--scrollbar-width);
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 0.375rem var(--color-grey);
        background-color: var(--color-transparent);
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--color-purple-very-dark-transparent);
        border: 0.063rem solid var(--color-grey);
    }
}

.horizontalScrollbar {
    overflow-x: auto;

    &::-webkit-scrollbar {
        height: var(--scrollbar-height);
    }
}

.verticalScrollbar {
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: var(--scrollbar-width);
    }
}
