.container {
    --container-margin: auto;
    --container-padding: auto;

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: var(--container-margin);
    padding: var(--container-padding);
    color: var(--color-turquoise);
    height: 2rem;
}

.button {
    margin-left: 0.5rem;
}

.isMobile {
    --container-margin: 0;
    --container-padding: 0 1.25rem;
}
