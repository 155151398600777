.info {
    --item-color: var(--color-white);
}

.success {
    --item-color: var(--color-turquoise);
}

.warning {
    --item-color: var(--color-yellow);
}

.error {
    --item-color: var(--color-scarlet);
}
