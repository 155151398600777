.outerContainer {
    --ticker-container-height: 1.25rem;
    --ticker-box-shadow: inset 0 -1rem 0.4rem var(--color-purple-very-dark-transparent);
    --ticker-font-size: clamp(0.7rem, 1.1vw, 0.9rem);

    height: var(--ticker-container-height);
    box-shadow: var(--ticker-box-shadow);
    font-size: var(--ticker-font-size);

    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.desktopTray {
    max-width: 100%;
}

.desktopCompact {
    max-width: calc(var(--card-dimension) * 4);
}

.desktopCompactWithRadiance {
    max-width: calc(var(--card-dimension) * 4 + var(--card-dimension) + 1rem);
}

.desktopStandard {
    max-width: calc(var(--card-dimension) * 7.5);
}

.desktopStandardWithRadiance {
    max-width: calc(var(--card-dimension) * 7.5 + var(--card-dimension) + 1rem);
}

.mobile {
    --ticker-container-height: 1rem;
    --ticker-box-shadow: inset 0 -0.6rem 0.4rem var(--color-purple-very-dark);
    --ticker-font-size: clamp(0.6rem, 1vw, 0.9rem);

    position: absolute;
    z-index: var(--z-index-one);
    bottom: calc(var(--card-dimension) * 1.41);
}
