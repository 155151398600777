.tickerItemContainer {
    top: -15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-shadow: var(--text-shadow-default);
    background: var(--text-label-background);
    color: var(--item-color);
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
    transition: transform 0.2s;
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slideOut {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

.tickerItemContainer.hidden {
    transform: translateX(-100%);
}

.tickerItemContainer.visible {
    animation-name: slideIn;
    transform: translateX(0);
}

.tickerItemContainer.exiting {
    animation-name: slideOut;
    transform: translateX(-100%);
}

.info {
    composes: info from '../../../../../../css/commonClasses/logLevels.css';
}

.success {
    composes: success from '../../../../../../css/commonClasses/logLevels.css';
}

.warning {
    composes: warning from '../../../../../../css/commonClasses/logLevels.css';
}

.error {
    composes: error from '../../../../../../css/commonClasses/logLevels.css';
}
