.modal.modal {
    z-index: var(--z-index-modal);
}

.content {
    --content-max-width: 65%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--color-purple-very-dark);
    border: var(--content-border);
    outline: var(--content-outline);
    box-shadow: var(--border-shadow-modal);
    padding: 2rem;
    max-width: var(--content-max-width);
    max-height: 100%;
}

@media (orientation: portrait) {
    .content {
        --content-max-width: 90%;
    }
}

.isMobile {
    --content-max-width: 90%;
}

.default {
    --content-border: var(--border-two-px-solid-white);
    --content-outline: none;
}

.red {
    --content-border: var(--border-one-and-half-px-solid-red);
    --content-outline: var(--border-one-and-half-px-solid-red);
}

.green {
    --content-border: var(--border-one-and-half-px-solid-green);
    --content-outline: var(--border-one-and-half-px-solid-green);
}
