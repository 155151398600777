.button.button {
    --active-button-width: 120%;
    --active-button-height: 100%;
    --active-button-animation-start-left: -100%;
    --button-box-shadow: none;
    --button-background-color: var(--color-white-transparent);

    border-radius: 0.313rem;
    background-color: var(--button-background-color);
    color: var(--color-white);
    vertical-align: middle;
    border: var(--border-two-px-groove-grey);
    box-shadow: var(--button-box-shadow);
    font-size: calc(var(--card-dimension) * 0.15);
    white-space: nowrap;
    width: 100%;
    height: 100%;
    min-width: 1.875rem;
    overflow: hidden;
    position: relative;
}

.button.button:hover {
    border: var(--border-two-px-groove-grey);
    background-color: var(--button-background-color);
    color: var(--color-white);
}

@media (hover: hover) {
    .button.button:hover {
        border-color: var(--color-essence);
        background-color: var(--color-blue-transparent);
        color: var(--color-white);
    }
}

.button.activeButton {
    --button-background-color: var(--color-essence-transparent);
    --button-box-shadow: var(--box-shadow-element-higher-layer);
}

.button.activeButton::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: var(--active-button-width);
    height: var(--active-button-height);
    background: linear-gradient(0deg, transparent, var(--color-purple), transparent);
    transform: rotate(45deg);
    animation: activeButtonWaveEffect 4s infinite linear;
    z-index: 1;
}

@keyframes activeButtonWaveEffect {
    0% {
        left: var(--active-button-animation-start-left);
    }
    100% {
        left: 100%;
    }
}

.button.mobileButton {
    --active-button-width: 230%;
    --active-button-height: 80%;
    --active-button-animation-start-left: -200%
}

.tooltip {
    margin: auto;
    font-size: var(--tooltip-font-size);
}
