.button {
    --button-padding: 0.2rem 1rem 0.2rem 0.2rem;
    --button-width: 100%;
    --button-height: 2.25rem;

    margin-left: 0.11rem;
    margin-right: 0.11rem;
    cursor: pointer;
    display: flex;
    padding: var(--button-padding);
    align-items: center;
    background-color: var(--color-discord);
    border-radius: 0.2rem;
    width: var(--button-width);
    height: var(--button-height);

    &:hover {
        background-color: var(--color-discord-transparent);
    }
}

.isIconOnly {
    --button-padding: 0.2rem;
    --button-width: var(--button-height);
}

.icon {
    font-size: calc(var(--button-height) * 0.9);
}

.textContainer {
    padding-left: 0.5rem;
}


