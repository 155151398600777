.text {
    --label-font-size: clamp(0.6rem, 1.2vw, 0.85rem);

    margin: 0;
    line-height: 1.5;
    font-size: var(--label-font-size);
    text-shadow: var(--label-text-shadow);
}

.default {
    --label-text-shadow: var(--text-shadow-default);
}

.red {
    --label-text-shadow: var(--text-shadow-red);
}
