@keyframes moveCard {
    from {
        transform: translate(var(--initial-x), var(--initial-y));
    }
    to {
        transform: translate(var(--final-x), var(--final-y));
    }
}

.movingCard {
    --initial-x: 0;
    --initial-y: 0;
    --final-x: 0;
    --final-y: 0;
    --duration: '0s';
    --delay: '0s';

    position: fixed;
    z-index: var(--z-index-moving-card);
    animation: moveCard var(--duration) var(--delay) cubic-bezier(.17,.42,.98,-0.22) forwards;
    display: inline-block;
    will-change: transform;
}
