.essenceBarBox {
    display: flex;
    justify-content: center;
}

.playerNameBox {
    position: absolute;
    top: -35%;
    left: -10%;
    width: 120%;
    z-index: var(--z-index-one);
    display: flex;
    justify-content: center;
}

.playerName {
    margin: auto;
    font-size: 0.55rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    overflow: hidden;
    width: 100%;
    text-shadow: var(--text-shadow-default);
    background: var(--text-label-background);
}
