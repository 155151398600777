.outerContainer {
    --outer-container-height: var(--card-dimension);
    padding: calc(var(--card-dimension) * 0.24);
    overflow-y: auto;
    height: var(--outer-container-height);
}

.innerContainer {
    height: var(--inner-container-height);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0.75rem;
}

.innerFitContent {
    --inner-container-height: fit-content;
}

.innerAutoFill {
    --inner-container-height: 100%;
}

.playersTopRow {
    padding-bottom: calc(var(--card-dimension) * 0.32);
    display: flex;
    justify-content: space-around;
}

.playersMiddleRow {
    padding-top: calc(var(--card-dimension) * 0.32);
    display: flex;
    justify-content: var(--justify-content-middle-row);
}

.middleMoreThanFive {
    --justify-content-middle-row: space-between;
}

.middleFiveAndLess {
    --justify-content-middle-row: space-around;
}
