.container {
    height: calc(var(--window-height) * 0.55);
    display: flex;
    flex-direction: column;
    justify-content: var(--justify-content-container);
}

.moreThanFive {
    --justify-content-container: space-between;
}

.fiveAndLess {
    --justify-content-container: space-around;
}

.topRow {
    display: flex;
    justify-content: space-around;
}

.middleBottomRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.playersColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(var(--window-height) / 3);
}

.bottom {
    margin-top: 1rem;
}

.rightColumn {
    display: flex;
    justify-content: flex-end;
}
