.container {
    position: fixed;
    pointer-events: none;
    z-index: var(--z-index-drawing-card);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.itemHidden {
    display: none;
}

.itemActive {
    --item-translate-x: 0px;
    --item-translate-y: 0px;
    transform: translate(var(--item-translate-x), var(--item-translate-y));
}
