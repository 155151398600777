.outerContainer {
    position: fixed;
    z-index: var(--z-index-log-journal);
}

.closeButtonContainer {
    display: flex;
    justify-content: flex-end;
}

.logList.logList {
    --log-list-padding: 0.25rem;

    padding: var(--log-list-padding);
}

.logElement {
    --log-element-padding-left: 0.5rem;
    --log-element-font-size: 0.8rem;

    padding-left: var(--log-element-padding-left);
    font-size: var(--log-element-font-size);
    color: var(--item-color);
    margin: auto auto auto 0;
}

.isMobile {
    --label-font-size: 0.65rem;
    --label-bottom: 20%;
    --log-element-padding-left: 0;
    --log-element-font-size: 0.7rem;
    --log-list-padding: 0.125rem;
}

.logsCard {
    background-color: var(--color-purple-very-dark-transparent);
    overflow-x: hidden;
    composes: defaultScrollbar from '../../css/commonClasses/scrollbar.css';
    composes: verticalScrollbar from '../../css/commonClasses/scrollbar.css';
}

.closeButton.closeButton {
    --close-button-padding: 0.5rem;

    border-radius: 0.4rem;
    place-items: flex-end;
    padding: var(--close-button-padding);
    min-width: 0;

    @media (max-height: 930px) {
        --close-button-padding: 0.1rem;
    }
}

.info {
    composes: info from '../../css/commonClasses/logLevels.css';
}

.success {
    composes: success from '../../css/commonClasses/logLevels.css';
}

.warning {
    composes: warning from '../../css/commonClasses/logLevels.css';
}

.error {
    composes: error from '../../css/commonClasses/logLevels.css';
}
